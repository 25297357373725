
import Vue from "vue"
import { getAttributes } from "~/utils/block"
import { replaceUrlS3 } from "~/utils/imageUrlS3"

interface BlockData {
  imageURL: string
  mobileImageURL: string
  tabletImageURL: string
  dialogContent: any[]
  dialogClass: string
}

interface ComponentData {
  blockData: BlockData
}

export default Vue.extend({
  name: "ImageDialog",

  props: {
    block: {
      type: Object as () => Record<string, any>,
      required: true
    },
    data: {
      type: Object as () => BlockData,
      required: false,
      default: () => ({})
    }
  },

  data(): ComponentData {
    return {
      blockData: {
        imageURL: "",
        mobileImageURL: "",
        tabletImageURL: "",
        dialogContent: [],
        dialogClass: ""
      }
    }
  },

  mounted(): void {
    this.mapData()
  },

  methods: {
    getAttributes,
    replaceUrlS3,

    mapData(): void {
      this.blockData.imageURL = this.block.innerBlocks[0].attributes.find(
        item => item.name === "url"
      ).value
      this.blockData.tabletImageURL = this.blockData.imageURL
      if (this.block.innerBlocks[1]) {
        this.blockData.tabletImageURL = this.block.innerBlocks[1]?.attributes.find(
          item => item.name === "url"
        ).value
      }
      this.blockData.mobileImageURL = this.blockData.tabletImageURL
      if (this.block.innerBlocks[2]) {
        this.blockData.mobileImageURL = this.block.innerBlocks[2]?.attributes.find(
          item => item.name === "url"
        ).value
      }

      document.documentElement.style.setProperty(
        "--dialog-section-background",
        `url(${this.replaceUrlS3(this.blockData.imageURL, this.$config)})`
      )
      document.documentElement.style.setProperty(
        "--dialog-section-tablet-background",
        `url(${this.replaceUrlS3(this.blockData.tabletImageURL, this.$config)})`
      )
      document.documentElement.style.setProperty(
        "--dialog-section-mobile-background",
        `url(${this.replaceUrlS3(this.blockData.mobileImageURL, this.$config)})`
      )

      this.blockData.dialogContent =
        this.block.innerBlocks[0].innerBlocks[1].innerBlocks[1].innerBlocks[0].innerBlocks
      this.blockData.dialogClass = this.getAttributes(
        this.block.innerBlocks[0].innerBlocks[1].innerBlocks[1].innerBlocks[0]
      ).class
    }
  }
})
