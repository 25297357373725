
import Vue from "vue"
import { extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import { mapGetters, mapActions } from "vuex"
import { getAttributes } from "~/utils/block"
import { SET_DELIVERY_ADDRESS, SET_LISTING } from "~/store/listing/actions"
import { GET_DELIVERY_ADDRESS } from "~/store/listing/getters"
import InitialListing from "~/store/listing/state"
import { placeToAddress } from "~/scripts/convert"

export default Vue.extend({
  name: "NewLocationDeliveryInput",

  components: {
    ValidationProvider
  },

  props: {
    block: {
      type: Object as () => Record<string, any>,
      required: true
    },
    virgin: {
      type: Boolean
    },
    lazy: {
      type: Boolean,
      default: true
    },
    validation: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters("listing", {
      getDeliveryAddress: GET_DELIVERY_ADDRESS
    }),

    attributes(): Record<string, any> | null {
      return this.virgin ? null : this.getAttributes(this.block)
    }
  },

  created() {
    if (this.validation) {
      extend("required", required)
    }
    this.setListing(InitialListing())
  },

  methods: {
    ...mapActions("listing", {
      setDeliveryAddress: SET_DELIVERY_ADDRESS,
      setListing: SET_LISTING
    }),

    getAttributes,

    setDeliveryPlace(place: google.maps.places.PlaceResult | null): void {
      this.setDeliveryAddress(place !== null ? placeToAddress(place) : null)
    }
  }
})
