
import Vue from "vue"
import axios from "axios"
import { formatDistance } from "date-fns"

interface ComponentData {
  reviews: Record<string, any>[]
  trustpilotTotalReviews: string
  trustpilotExcellentReviewsRounded: string
  trustpilotRating: number
}

export default Vue.extend({
  name: "TrustpilotReviews",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },

  data(): ComponentData {
    return {
      reviews: [],
      trustpilotTotalReviews: "0",
      trustpilotExcellentReviewsRounded: "0",
      trustpilotRating: 0
    }
  },

  computed: {
    title(): string {
      return this.block.innerBlocks[0].innerBlocks[0].innerBlocks[0].innerHtml.replace(
        new RegExp("<h2[^>]*>+(.*)</h2>"),
        "$1"
      )
    }
  },

  methods: {
    reviewHref(href: string): string {
      return href.replace("api.trustpilot.com/v1/reviews/", "www.trustpilot.com/reviews/")
    },

    formatDistance(date: string): string {
      return formatDistance(new Date(), new Date(date))
    },

    async created(): Promise<void> {
      const trustPilotConfig = this.$config.trustPilot
      const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotConfig.id}`
      const trustpilotURLReviews = `https://api.trustpilot.com/v1/business-units/${trustPilotConfig.id}/reviews`

      try {
        const axiosInstance = axios.create() as any
        const response = await axiosInstance.get(trustpilotURL, {
          params: {
            apikey: trustPilotConfig.apiKey
          }
        })

        if (response.status === 200 && response.data) {
          this.trustpilotRating = response.data.score.trustScore
          this.trustpilotTotalReviews = Number(response.data.numberOfReviews.total).toLocaleString()
          this.trustpilotExcellentReviewsRounded = Number(
            Math.floor(response.data.numberOfReviews.fiveStars / 1000) * 1000
          ).toLocaleString()
        }

        const responseReviews = await axiosInstance.get(trustpilotURLReviews, {
          params: {
            apikey: trustPilotConfig.apiKey,
            stars: [5]
          }
        })

        if (responseReviews.status === 200 && responseReviews.data) {
          this.reviews = responseReviews.data.reviews
        }
      } catch (err) {
        console.log("Trustpilot error", err)
      }
    }
  }
})
