
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_IS_SMALL_OR_MEDIUM } from "~/store/pages/getters"

export default Vue.extend({
  name: "HeaderCards",
  computed: {
    ...mapGetters("pages", {
      isMobile: GET_IS_SMALL_OR_MEDIUM
    })
  },
  mounted() {
    if (!this.isMobile) {
      // The <a> href attribute is not reactive in Nuxt2 so we need this workaround.
      const furnitureCard = document.getElementById("furniture-card")
      if (furnitureCard) {
        furnitureCard.setAttribute("href", "/instant-prices/furniture/listing-items")
      }
    }
  },
  methods: {
    trackCardClick(title: string) {
      this.$snowplow.trackCategoryCardClick(title)
    }
  }
})
