
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import { GET_NODES } from "~/store/pages/getters"
import { LOAD_PAGES } from "~/store/pages/actions"
import { getAttribute } from "~/utils/block"
import { replaceUrlS3 } from "~/utils/imageUrlS3"
import { PageFilterService } from "~/services/public/PageFilter"
import { TagsService } from "~/services/public/Tags"

interface ComponentData {
  tiles: Page[]
}

export default Vue.extend({
  name: "PostTiles",

  fetchKey: "post-tiles",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },

  data(): ComponentData {
    return {
      tiles: []
    }
  },

  computed: {
    ...mapGetters("pages", {
      // probably later we can add a computed property for this using the 'skip-publish' tag
      getNodes: GET_NODES
    })
  },

  methods: {
    getAttribute,
    replaceUrlS3,

    ...mapActions("pages", {
      loadPages: LOAD_PAGES
    }),

    stripTrailingSlash(uri: string): string {
      return uri.replace(/\/$/, "")
    },

    async fetch(): Promise<void> {
      if (process.server) {
        const query = JSON.parse(this.getAttribute(this.block.attributes, "query"))

        const where = {
          categoryIn: query.taxQuery.category.join(),
          tagNotIn: [] as string[]
        }

        const tagsService = new TagsService(this.$config)
        const pageFilterService = new PageFilterService(tagsService)
        const tagsNotIn = await pageFilterService.getSkipPublishTags()

        if (tagsNotIn.length) {
          where.tagNotIn = tagsNotIn
        }

        await this.loadPages({
          config: this.$config,
          options: {
            after: "",
            first: 50,
            where
          }
        })
      }
    }
  }
})
