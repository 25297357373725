import { formatAddress } from "~/scripts/useful-functions"

/**
 * Converting Google PlaceResult object to AnyVan Address
 *
 * @param place Google result
 */
export function placeToAddress(place): Address {
  if (typeof place.address_components === "undefined") {
    throw new TypeError("Invalid place object")
  }

  const response = place.address_components.reduce((acc, component) => {
    component.types.forEach(type => {
      acc[type] = {
        long_name: component.long_name,
        short_name: component.short_name
      }
    })
    return acc
  }, {})

  const address: Address = {
    postcode: response.postal_code?.long_name || "",
    postcode_short: "",
    addressLine1: "",
    addressLine2: null,
    city: response.postal_town?.long_name || response.locality?.long_name,
    region: response.administrative_area_level_2?.long_name,
    country: response.country?.short_name || "",
    coordinates: {
      lat: null,
      lng: null
    },
    place
  }

  address.addressLine1 = formatAddress(
    response.route?.long_name,
    response.street_number?.long_name,
    address.country as string
  )

  if (typeof place.geometry !== "undefined") {
    address.coordinates = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
  }

  return address
}
