
import Vue from "vue"
import { getAttributes, getAttribute, patternCheck } from "~/utils/block"
import { CATEGORY_V4_ID_FURNITURE, CATEGORY_V4_ID_HOUSE_MOVE } from "~/constants/category"
import {
  CHECK_LIST,
  COMPARISON_TABLE_ITEM,
  COMPARISON_TABLE_SECTION,
  CORE_BUTTON,
  CORE_COVER,
  CORE_IMAGE,
  CORE_PARAGRAPH,
  FAQ_SECTION,
  HEADER,
  HOME_PAGE_HEADER,
  ICON_TABLE,
  IMAGE_DIALOG,
  IMAGE_OVERLAY,
  IMAGE_TEXT_SECTION,
  INTERNAL_REVIEWS,
  INTERNAL_REVIEW_SECTION,
  LINK_PHONE,
  LINK_PHONE_LINK_ONLY,
  LINK_TABLE,
  REMOVALS_FORM,
  OFFICE_FORM,
  CTA_FORM,
  LOCATION_FORM,
  LOCATION_FORM_EXTENSION,
  OTHER_SERVICES_SECTION,
  PRICE_BANNER,
  RECENT_MOVES_SECTION,
  RESPONSIVE_IMAGE_SET,
  TRUSTPILOT_CAROUSEL,
  CAROUSEL_SWIPER,
  VALUE_ADD_SECTION,
  CATEGORY_CARD,
  TRUSTPILOT_WIDGET,
  HEADER_CARDS,
  MEDIA_TEXT_RESPONSIVE,
  EXPERIMENT,
  MAPBOX_LOCAL_MOVES_MAP,
  HEADER_NARROW_LHS,
  MAP_PANEL_CTAS,
  AV_SAVINGS,
  AV_LOCAL_SUMMARY,
  SERVICE_HIGHLIGHT,
  GO_LOCAL_HEADER,
  GO_LOCAL_ACCOUNT_MANAGER,
  GO_LOCAL_REVIEWS,
  BREADCRUMB,
  AV_BACKGROUND_IMAGE,
  AV_DYNAMIC_CONTENT,
  AV_WHATSAPP_BUTTON
} from "~/constants/blocks"

interface ImageData {
  id: string | null
  url: string | null
  width?: number
  height?: number
}

export default Vue.extend({
  name: "Block",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    blockId: {
      type: String,
      default: ""
    },
    virgin: {
      type: Boolean
    },
    lazy: {
      type: Boolean
    },
    isRebrand: {
      type: Boolean
    },
    isPPC: {
      type: Boolean
    }
  },

  data() {
    return {
      CHECK_LIST,
      COMPARISON_TABLE_ITEM,
      COMPARISON_TABLE_SECTION,
      CORE_BUTTON,
      CORE_COVER,
      CORE_IMAGE,
      CORE_PARAGRAPH,
      FAQ_SECTION,
      HEADER,
      HOME_PAGE_HEADER,
      ICON_TABLE,
      IMAGE_DIALOG,
      IMAGE_OVERLAY,
      IMAGE_TEXT_SECTION,
      INTERNAL_REVIEWS,
      INTERNAL_REVIEW_SECTION,
      LINK_PHONE,
      LINK_PHONE_LINK_ONLY,
      LINK_TABLE,
      REMOVALS_FORM,
      OFFICE_FORM,
      CTA_FORM,
      LOCATION_FORM,
      LOCATION_FORM_EXTENSION,
      OTHER_SERVICES_SECTION,
      PRICE_BANNER,
      RECENT_MOVES_SECTION,
      RESPONSIVE_IMAGE_SET,
      TRUSTPILOT_CAROUSEL,
      CAROUSEL_SWIPER,
      VALUE_ADD_SECTION,
      CATEGORY_CARD,
      TRUSTPILOT_WIDGET,
      HEADER_CARDS,
      MEDIA_TEXT_RESPONSIVE,
      EXPERIMENT,
      MAPBOX_LOCAL_MOVES_MAP,
      HEADER_NARROW_LHS,
      MAP_PANEL_CTAS,
      AV_SAVINGS,
      AV_LOCAL_SUMMARY,
      SERVICE_HIGHLIGHT,
      GO_LOCAL_HEADER,
      GO_LOCAL_ACCOUNT_MANAGER,
      GO_LOCAL_REVIEWS,
      BREADCRUMB,
      AV_BACKGROUND_IMAGE,
      AV_DYNAMIC_CONTENT,
      CATEGORY_V4_ID_FURNITURE,
      CATEGORY_V4_ID_HOUSE_MOVE,
      AV_WHATSAPP_BUTTON
    }
  },

  computed: {
    phoneNumber(): string | null {
      const phoneNumberMatch = this.block.innerHtml.match(/<a[^>]*>+(.*)<\/a>/)
      return phoneNumberMatch ? phoneNumberMatch[1] : null
    },

    imageId(): string | null {
      const imageId = this.block.attributes.find(attribute => attribute.name === "id")
      return imageId ? imageId.value : null
    },

    imageUrl(): string | null {
      if (!this.block.innerHtml) {
        return null
      }

      const regex = /src="([^"]+)"/
      const src = regex.exec(this.block.innerHtml)

      if (src) {
        return src[1]
      }

      console.warn("The page includes image without source!")
      return null
    },

    attributeClasses(): string {
      return this.getAttributes(this.block).class
    },

    getImageData(): ImageData {
      const imageData: ImageData = {
        id: this.imageId,
        url: this.imageUrl
      }

      const width = this.getAttribute(this.block.attributes, "width")
      const height = this.getAttribute(this.block.attributes, "height")

      if (width && height) {
        Object.assign(imageData, { width, height })
      }

      return imageData
    },

    imageCaption(): string | null {
      const regex = /<figcaption>([^"]+)<\/figcaption>/
      const caption = regex.exec(this.block.innerHtml)

      return caption ? caption[1] : null
    }
  },

  methods: {
    getAttributes,
    getAttribute,
    patternCheck,

    isLinkOnly(innerHtml: string): boolean {
      const pHtml = innerHtml.replace(/<a[^>]*>(.+?)<\/a>/, "")
      const pText = pHtml.replace(/<p[^>]*>(.+?)<\/p>/, "$1")
      return !pText
    },

    getHref(innerHtml: string): string {
      const hrefArray = /href="([^'"]+)/g.exec(innerHtml)
      return hrefArray ? hrefArray[1] : ""
    },

    getLink(innerHtml: string): string {
      return innerHtml.replace(/(<([^>]+)>)/gi, "")
    },

    getLinkClass(block: Block): string | undefined {
      const attributes = this.getAttributes(block)
      return attributes.class?.replace("av-paragraph ", "")
    }
  }
})
