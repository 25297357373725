
import Vue from "vue"

export default Vue.extend({
  name: "BackgroundImage",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    }
  }
})
