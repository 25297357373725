
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_EXPERIMENTS_DATA } from "~/store/experiments/getters"

interface ComponentData {
  displayBlock: boolean
}

export default Vue.extend({
  name: "Experiment",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  data(): ComponentData {
    return {
      displayBlock: false
    }
  },

  computed: {
    ...mapGetters("experiments", {
      experiments: GET_EXPERIMENTS_DATA
    }),

    experimentData(): { experimentName: string; variantName: string } | undefined {
      if (this.block.experiment !== undefined) {
        return this.block.experiment
      }
      return this.getExperimentDataFromAttributes()
    }
  },

  watch: {
    experiments: {
      handler(): void {
        this.updateDisplayBlock()
      }
    }
  },

  methods: {
    updateDisplayBlock(): void {
      if (!this.experimentData) {
        return
      }
      // check if we have experiment our experiment in vuex
      if (this.experiments && this.experiments.get(this.experimentData.experimentName)) {
        const testLabVariant = this.experiments.get(this.experimentData.experimentName)
        this.displayBlock = testLabVariant === this.experimentData.variantName
        return
      }
      this.displayBlock = this.experimentData.variantName === "control"
    },

    getExperimentDataFromAttributes(): { experimentName: string; variantName: string } | undefined {
      const attributes = this.block.attributes
      const classNameAttribute = attributes.find(attr => attr.name === "className")
      if (classNameAttribute && classNameAttribute.value) {
        const classNames = classNameAttribute.value.split(" ")
        const experimentName = classNames.find(name => name.startsWith("avx-name-"))
        const variantName = classNames.find(name => name.startsWith("avx-variant-"))

        return {
          experimentName: experimentName ? experimentName.substring("avx-name-".length) : "",
          variantName: variantName ? variantName.substring("avx-variant-".length) : ""
        }
      }
    }
  }
})
