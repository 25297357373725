
import Vue from "vue"
import { getBlockAttribute } from "~/utils/block"

export default Vue.extend({
  name: "RecentMovesSection",
  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    blockId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {}
  },

  methods: {
    hasLandscapeImages(block: Block): boolean {
      return getBlockAttribute(block.attributes, "className").includes("landscape-image")
    }
  }
})
