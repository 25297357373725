
import Vue from "vue"

export default Vue.extend({
  name: "HomePageHeader",
  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },
  head() {
    return {
      link: [
        {
          rel: "alternate",
          hreflang: "en",
          href: "https://www.anyvan.com/"
        },
        {
          rel: "alternate",
          hreflang: "es",
          href: "https://www.anyvan.com/es"
        },
        {
          rel: "alternate",
          hreflang: "x-default",
          href: "https://www.anyvan.com/"
        }
      ]
    }
  },
  data() {
    return {
      emailFormShow: false
    }
  },
  mounted() {
    const makeLinkTogglable = (link: Node) => {
      link.addEventListener("click", () => {
        this.toggleModal()
      })
    }
    const quoteLink = document.querySelectorAll(".quote-link")
    if (quoteLink && quoteLink.length) {
      quoteLink.forEach(makeLinkTogglable)
    }

    const buttons = document.querySelectorAll(".homepage-ctas .av-buttons")
    if (buttons && buttons.length) {
      buttons.forEach(button => {
        button.addEventListener("click", () => {
          this.sendHomePageCtaTrackingEvent()
        })
      })
    }
  },
  methods: {
    toggleModal() {
      this.emailFormShow = !this.emailFormShow
    },
    sendHomePageCtaTrackingEvent() {
      this.$snowplow.trackHomePageCtaClick()
    }
  }
})
