
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"

export default Vue.extend({
  name: "PhoneNumber",

  props: {
    inheritColor: {
      type: Boolean
    },
    inheritFontSize: {
      type: Boolean
    },
    showIcon: {
      type: Boolean
    },
    showText: {
      type: Boolean
    },
    showButton: {
      type: Boolean
    },
    showButtonText: {
      type: Boolean
    },
    fontSize: {
      type: Number
    },
    iconSize: {
      type: Number,
      default: 28
    },
    buttonText: {
      type: String
    },
    linkClass: {
      type: String,
      default: ""
    },
    icon: {
      type: Array,
      default: () => ["custom", "telephone-circle-1"]
    },
    handleClick: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    }),

    fontStyle(): string[] {
      const styles: string[] = []

      if (this.fontSize) {
        styles.push(`font-size: ${this.fontSize}px`)
      }

      return styles
    }
  }
})
