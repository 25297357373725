
import Vue from "vue"
import { getAttribute, getAttributes } from "~/utils/block"

export default Vue.extend({
  name: "CoreCover",

  props: {
    block: {
      type: Object as () => Record<string, any>,
      required: true
    },
    virgin: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    imageData(): { url: string; id: string } {
      const imageUrl = this.block.attributes.find(attribute => attribute.name === "url")

      const imageId = this.block.attributes.find(attribute => attribute.name === "id")

      return { url: imageUrl.value, id: imageId.value }
    },

    attributes(): Record<string, any> | null {
      return this.virgin ? null : this.getAttributes(this.block)
    }
  },

  methods: {
    getAttribute,
    getAttributes
  }
})
