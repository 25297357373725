
import Vue from "vue"
import { v4Url } from "~/services/public/Listing"

interface ComponentData {
  preListingFurniture: string
  preListingHouseMove: string
}

export default Vue.extend({
  name: "HeaderCards",
  data(): ComponentData {
    return {
      preListingFurniture: "/es/" + v4Url.preListing.furniture,
      preListingHouseMove: "/es/" + v4Url.preListing.houseMove
    }
  },
  methods: {
    trackCardClick(title: string) {
      this.$snowplow.trackCategoryCardClick(title)
    },
    getAbsoluteUrl(path: string) {
      return `${this.$config.v4Host.replace("com", this.$i18n.locale)}/${path}`
    }
  }
})
