
import Vue_ from "vue"
import { VueConstructor } from "vue/types/umd"
import { format } from "date-fns"
import axios from "axios"
import { CORE_PARAGRAPH, CORE_HEADING } from "~/constants/blocks"
import { getCategoryByV4Id } from "~/utils/categories"

interface ComponentData {
  CORE_PARAGRAPH: string
  CORE_HEADING: string
  formatedReviews: Record<string, any>[]
}

interface PageLayoutInject {
  internalReviewsFilter: Record<string, any>
}

const Vue = Vue_ as VueConstructor<Vue_ & PageLayoutInject>
export default Vue.extend({
  name: "InternalReviewsComponent",

  inject: {
    internalReviewsFilter: {
      from: "internalReviewsFilter"
    }
  },

  props: {
    block: {
      type: Object,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  data(): ComponentData {
    return {
      CORE_PARAGRAPH,
      CORE_HEADING,
      formatedReviews: []
    }
  },

  created(): void {
    this.getReviews()
  },

  methods: {
    getCategoryByV4Id,

    date(rawDate: string): string {
      return format(new Date(rawDate), "d MMMM yyyy")
    },

    async getReviews(): Promise<void> {
      try {
        const axiosInstance = axios.create()

        axiosInstance.defaults.baseURL = process.server
          ? this.$config.baseApiUrl.server
          : this.$config.baseApiUrl.client

        const response = await axiosInstance.get("/v1/listings/nearby-feedback", {
          params: {
            placeId: this.internalReviewsFilter.placeId,
            zoomLevel: this.internalReviewsFilter.zoomLevel,
            categoryIds: this.internalReviewsFilter.categoryIds,
            minStars: this.internalReviewsFilter.minStars,
            limit: this.internalReviewsFilter.limit
          }
        })

        if (response.status === 200 && response.data) {
          console.log(response.data)
          this.formatReviews(response.data)
        }
      } catch (err) {
        console.warn(err)
      }

      // for testing temporary mock:
      /* const reviews: Record<string, any> = [
        {
          listingId: 1,
          listingTitle: 'Moved muy stuff',
          listingCategoryId: 1,
          listingCreatedAt: '2023-02-10T08:55:33.285Z',
          providerPunctuality: 3,
          providerCommunication: 5,
          providerCareOfGoods: 5,
          providerPresentation: 5,
          anyvanServiceOverall: 1,
          customerExperience:
            'This company provided me with excellent service and good communication. I highly recommend them and will certainly be using their services again in the future',
          editedBy: 1,
          editedAt: '2023-02-10T08:55:33.285Z',
          status: 1,
          isAdminApproved: 1,
          isTpScoreProcessed: 1,
          sentToFreshdesk: 1,
          createdAt: '2023-02-10T08:55:33.285Z',
          providerWearingMask: 1,
        },
        {
          listingId: 1,
          listingTitle: 'Moved muy stuff',
          listingCategoryId: 1,
          listingCreatedAt: '2023-02-10T08:55:33.285Z',
          providerPunctuality: 5,
          providerCommunication: 4,
          providerCareOfGoods: 5,
          providerPresentation: 5,
          anyvanServiceOverall: 1,
          customerExperience:
            'This company provided me with excellent service and good communication. I highly recommend them and will certainly be using their services again in the future',
          editedBy: 1,
          editedAt: '2023-02-10T08:55:33.285Z',
          status: 1,
          isAdminApproved: 1,
          isTpScoreProcessed: 1,
          sentToFreshdesk: 1,
          createdAt: '2023-02-10T08:55:33.285Z',
          providerWearingMask: 1,
        },
        {
          listingId: 1,
          listingTitle: 'Moved muy stuff',
          listingCategoryId: 1,
          listingCreatedAt: '2023-02-10T08:55:33.285Z',
          providerPunctuality: 4,
          providerCommunication: 4,
          providerCareOfGoods: 5,
          providerPresentation: 4,
          anyvanServiceOverall: 1,
          customerExperience:
            'This company provided me with excellent service and good communication. I highly recommend them and will certainly be using their services again in the future',
          editedBy: 1,
          editedAt: '2023-02-10T08:55:33.285Z',
          status: 1,
          isAdminApproved: 1,
          isTpScoreProcessed: 1,
          sentToFreshdesk: 1,
          createdAt: '2023-02-10T08:55:33.285Z',
          providerWearingMask: 1,
        },
        {
          listingId: 1,
          listingTitle: 'Moved muy stuff',
          listingCategoryId: 1,
          listingCreatedAt: '2023-02-10T08:55:33.285Z',
          providerPunctuality: 5,
          providerCommunication: 5,
          providerCareOfGoods: 5,
          providerPresentation: 5,
          anyvanServiceOverall: 1,
          customerExperience:
            'This company provided me with excellent service and good communication. I highly recommend them and will certainly be using their services again in the future',
          editedBy: 1,
          editedAt: '2023-02-10T08:55:33.285Z',
          status: 1,
          isAdminApproved: 1,
          isTpScoreProcessed: 1,
          sentToFreshdesk: 1,
          createdAt: '2023-02-10T08:55:33.285Z',
          providerWearingMask: 1,
        },
        {
          listingId: 1,
          listingTitle: 'Moved muy stuff',
          listingCategoryId: 1,
          listingCreatedAt: '2023-02-10T08:55:33.285Z',
          providerPunctuality: 1,
          providerCommunication: 1,
          providerCareOfGoods: 1,
          providerPresentation: 1,
          anyvanServiceOverall: 1,
          customerExperience:
            'This company provided me with excellent service and good communication. I highly recommend them and will certainly be using their services again in the future',
          editedBy: 1,
          editedAt: '2023-02-10T08:55:33.285Z',
          status: 1,
          isAdminApproved: 1,
          isTpScoreProcessed: 1,
          sentToFreshdesk: 1,
          createdAt: '2023-02-10T08:55:33.285Z',
          providerWearingMask: 1,
        },
      ]

      this.formatReviews(reviews) */
    },

    formatReviews(reviews: any[]): void {
      if (reviews.length) {
        reviews.forEach(review => {
          this.formatedReviews.push({
            id: review.id,
            title:
              this.getCategoryByV4Id(review.listingCategoryId)!.name +
              " job - " +
              review.listingTitle +
              " from London to London",
            rating:
              (review.providerPunctuality +
                review.providerCommunication +
                review.providerCareOfGoods +
                review.providerPresentation) /
              4,
            customerExperience: review.customerExperience,
            createdAt: review.createdAt,
          })
        })
      }
    }
  }
})
