// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.phoneNumberComponent_vLRNK svg {\n    margin-right: 8px;\n    top: auto;\n    top: initial\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneNumberComponent": "phoneNumberComponent_vLRNK"
};
module.exports = ___CSS_LOADER_EXPORT___;
