
import Vue from "vue"
import { extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import { mapGetters, mapActions } from "vuex"
import { getAttributes } from "~/utils/block"
import {
  SET_PICKUP_PROPERTY_TYPE,
  SET_LISTING,
  SET_PICKUP_FLOOR,
  SET_PICKUP_LIFT_AVAILABLE
} from "~/store/listing/actions"
import {
  GET_PICKUP_FLOOR,
  GET_PICKUP_PROPERTY_TYPE,
  GET_PICKUP_LIFT_AVAILABLE
} from "~/store/listing/getters"
import InitialListing from "~/store/listing/state"
import { isHouse, isFlat } from "~/services/public/Listing"
import {
  FLOOR_GROUND,
  FLOORS,
  LIFT_AVAILABLE,
  LIFT_UNAVAILABLE,
  PROPERTY_TYPE_LIST_FLAT,
  PROPERTY_TYPE_LIST_HOUSE,
  PROPERTY_TYPE_LIST_MAIN
} from "~/constants/listing"
import {
  GET_FLOORS,
  GET_PROPERTY_TYPES,
  GET_PROPERTY_TYPES_MAIN,
  GET_PROPERTY_TYPES_FLAT,
  GET_PROPERTY_TYPES_HOUSE
} from "~/store/property/getters"
import { swapFirstTwoElements } from "~/scripts/useful-functions"

interface ComponentData {
  propertyTypesMain: any[]
  propertyTypesHouse: any[]
  propertyTypesFlat: any[]
  floors: any[]
}

export default Vue.extend({
  name: "PropertyPickupInput",

  components: { ValidationProvider },

  props: {
    propertyTypeHidden: {
      type: Boolean
    },
    propertyFloorHidden: {
      type: Boolean
    },
    block: {
      type: Object as () => Block,
      required: true
    },
    virgin: {
      type: Boolean
    },
    lazy: {
      type: Boolean,
      default: true
    },
    validation: {
      type: Boolean
    }
  },

  data(): ComponentData {
    return {
      propertyTypesMain: PROPERTY_TYPE_LIST_MAIN,
      propertyTypesHouse: PROPERTY_TYPE_LIST_HOUSE,
      propertyTypesFlat: PROPERTY_TYPE_LIST_FLAT,
      floors: FLOORS
    }
  },

  computed: {
    ...mapGetters("listing", {
      getPickupPropertyType: GET_PICKUP_PROPERTY_TYPE,
      getPickupFloor: GET_PICKUP_FLOOR,
      getPickupLiftAvailable: GET_PICKUP_LIFT_AVAILABLE
    }),
    ...mapGetters("property", {
      getStorePropertyTypes: GET_PROPERTY_TYPES,
      getStorePropertyTypesMain: GET_PROPERTY_TYPES_MAIN,
      getStorePropertyTypesHouse: GET_PROPERTY_TYPES_HOUSE,
      getStorePropertyTypesFlat: GET_PROPERTY_TYPES_FLAT,
      getStoreFloors: GET_FLOORS
    }),

    getPropertyTypesMain(): any[] {
      if (!this.getStorePropertyTypesMain) return this.propertyTypesMain

      const propertyTypesToShow = this.getStorePropertyTypesMain.filter(type => !type.hidden)

      if (this.$i18n.locale === "es") {
        /* Spain-only experiment where we invert the order of house and flat to test a hypothesis */
        return swapFirstTwoElements(propertyTypesToShow)
      }
      return propertyTypesToShow
    },

    getPropertyTypesHouse(): any[] {
      return this.getStorePropertyTypesHouse || this.propertyTypesHouse
    },

    getPropertyTypesFlat(): any[] {
      return this.getStorePropertyTypesFlat || this.propertyTypesFlat
    },

    getFloors(): any[] {
      return this.getStoreFloors || this.floors
    },

    attributes(): any {
      return this.virgin ? null : this.getAttributes(this.block)
    },

    pickupPropertyType: {
      get(): string | null {
        return this.getPickupPropertyType
      },
      set(value: string | null): void {
        if (!this.isFlat(value as string) || this.getPickupFloor === FLOOR_GROUND) {
          this.setPickupFloor(FLOOR_GROUND)
          this.setLiftAvailable(null)
        }
        this.setPickupPropertyType(value)
      }
    },

    pickupFloor: {
      get(): string | null {
        return this.getPickupFloor
      },
      set(value: string | null): void {
        if (value === FLOOR_GROUND) {
          this.setLiftAvailable(null)
        }
        this.setPickupFloor(value)
      }
    },

    liftAvailable: {
      get(): LiftAvailable {
        if (this.getPickupLiftAvailable === null) {
          this.setLiftAvailable(LIFT_AVAILABLE)
          return LIFT_AVAILABLE
        }
        return this.getPickupLiftAvailable
      },
      set(value: LiftAvailable): void {
        this.setLiftAvailable(value)
      }
    },

    getLiftAvailableOptions(): any[] {
      return [
        {
          name: this.$t("location_form.lift_available"),
          value: LIFT_AVAILABLE
        },
        {
          name: this.$t("location_form.lift_not_available"),
          value: LIFT_UNAVAILABLE
        }
      ]
    }
  },

  created() {
    if (this.validation) {
      extend("required", required)
    }
    this.setListing(InitialListing())
  },

  methods: {
    ...mapActions("listing", {
      setPickupFloor: SET_PICKUP_FLOOR,
      setListing: SET_LISTING,
      setPickupPropertyType: SET_PICKUP_PROPERTY_TYPE,
      setLiftAvailable: SET_PICKUP_LIFT_AVAILABLE
    }),

    getAttributes,

    isHouse(propertyType: string): boolean {
      return isHouse(propertyType) as boolean
    },

    isFlat(propertyType: string): boolean {
      return isFlat(propertyType) as boolean
    },

    requiresFloor(propertyType: string): boolean {
      if (!this.getStorePropertyTypes) {
        return this.propertyTypeHidden && !this.propertyFloorHidden
      }
      const propertyTypeObject: undefined | ListingPropertyType = this.getStorePropertyTypes.find(
        storePropertyType => storePropertyType.value === propertyType
      )
      return !!propertyTypeObject?.propertyWithLevels
    }
  }
})
