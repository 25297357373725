
import Vue from "vue"
import { patternCheck, getAttributes } from "~/utils/block"
import {
  AV_FAQ,
  AV_FAQ_OLD,
  AV_DYNAMIC_FAQ_CONTENT,
  BREADCRUMBS,
  CORE_MEDIA_TEXT,
  CORE_QUERY,
  FAQS_WRAPPER,
  PAGE_TILE_NAVIGATION,
  PAGE_TILE_NAVIGATION_FULL_IMAGE,
  TRUSTPILOT_REVIEWS
} from "~/constants/blocks"

export default Vue.extend({
  name: "WrapperBlock",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    blockId: {
      type: String,
      required: true
    },
    virgin: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    },
    isRebrand: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      AV_FAQ,
      AV_FAQ_OLD,
      AV_DYNAMIC_FAQ_CONTENT,
      BREADCRUMBS,
      CORE_MEDIA_TEXT,
      CORE_QUERY,
      FAQS_WRAPPER,
      PAGE_TILE_NAVIGATION,
      PAGE_TILE_NAVIGATION_FULL_IMAGE,
      TRUSTPILOT_REVIEWS
    }
  },

  computed: {
    attributes(): Record<string, any> | null {
      return this.virgin ? null : this.getAttributes(this.block)
    }
  },

  methods: {
    patternCheck,
    getAttributes
  }
})
