
import Vue from "vue"
import { MetaInfo } from "vue-meta"
import { getMediaById } from "~/services/public/Pages"
import { replaceUrlS3 } from "~/utils/imageUrlS3"

interface ComponentData {
  image: {
    width: number | string | undefined
    height: number | string | undefined
    sourceUrl?: string
    srcSet?: string | null
    altText?: string
    mediaDetails?: {
      width: number
      height: number
      sizes?: Array<{
        width: number
        height: number
        sourceUrl: string
        name?: string
      }>
    }
  }
}

interface ImageData {
  width: number
  height: number
  sourceUrl: string
}

export default Vue.extend({
  name: "OptimizedSource",

  props: {
    media: {
      type: String,
      default: ""
    },
    imageData: {
      type: Object,
      required: true
    },
    lazy: {
      type: Boolean
    },
    idType: {
      type: String,
      default: "DATABASE_ID"
    },
    size: {
      type: String,
      default: null
    }
  },

  data(): ComponentData {
    return {
      image: {
        width: "auto",
        height: "auto"
      }
    }
  },

  async fetch() {
    if (this.imageData.id) {
      try {
        const response = await getMediaById(this.$config, this.imageData.id, this.idType)

        if (response.data && response.data.mediaItem) {
          const media = response.data.mediaItem
          const selectedSize =
            media.mediaDetails.sizes && this.size && this.size != "full"
              ? media.mediaDetails.sizes.find(size => size.name === this.size)
              : null

          if (selectedSize) {
            this.image.width = selectedSize.width
            this.image.height = selectedSize.height
            this.image.sourceUrl = this.replaceUrlS3(selectedSize.sourceUrl, this.$config)
            this.image.srcSet = null
            this.image.altText = media.altText
          } else {
            this.image = media
            this.image.srcSet = this.replaceUrlS3(this.image?.srcSet ?? null, this.$config)

            if (process.client && media.mediaDetails.sizes && media.mediaDetails.sizes.length > 1) {
              media.mediaDetails.sizes.push({
                width: this.image.mediaDetails?.width,
                height: this.image.mediaDetails?.height,
                sourceUrl: this.image.sourceUrl
              })

              const theBestImage = this.getBestImage(media.mediaDetails.sizes)

              this.image.width = theBestImage.width
              this.image.height = theBestImage.height
              this.image.sourceUrl = this.replaceUrlS3(theBestImage.sourceUrl, this.$config)
            } else {
              this.image.width = this.image.mediaDetails?.width
              this.image.height = this.image.mediaDetails?.height
              this.image.sourceUrl = this.replaceUrlS3(media.sourceUrl, this.$config)
            }
          }
        }
      } catch (err) {
        console.log("Media error", err)
      }
    }

    if (this.imageData.width || this.imageData.height) {
      this.image.width = this.imageData.width
      this.image.height = this.imageData.height
    }
  },

  head(): MetaInfo {
    if (!this.lazy && this.media) {
      return {
        link: [
          {
            rel: "preload",
            as: "image",
            imagesrcset: this.image.sourceUrl
              ? this.replaceUrlS3(this.image.sourceUrl, this.$config)
              : this.replaceUrlS3(this.imageData.url, this.$config),
            media: this.media
          }
        ]
      }
    }
    return {}
  },

  methods: {
    replaceUrlS3,

    getBestImage(images: Array<ImageData>): ImageData {
      let bestImage = {
        sourceUrl: "",
        width: 0,
        height: 0
      }
      const minWidth = window.innerWidth

      images.forEach(element => {
        if (
          !bestImage.width ||
          (Number(element.width) > Number(bestImage.width) && Number(bestImage.width) < minWidth) ||
          (Number(element.width) >= minWidth &&
            (Number(bestImage.width) < minWidth || Number(element.width) < Number(bestImage.width)))
        ) {
          bestImage = Object.assign(bestImage, element)
        }
      })

      return bestImage
    }
  }
})
