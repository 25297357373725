
import Vue from "vue"
import { MetaInfo } from "vue-meta"
import { JsonObj } from "vue-meta/types/vue-meta"
import { mapGetters } from "vuex"
import { getAttributes } from "~/utils/block"
import { GET_BREADCRUMBS } from "~/store/pages/getters"

export default Vue.extend({
  name: "Breadcrumbs",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },

  head(): MetaInfo {
    return {
      script: [{ type: "application/ld+json", json: this.getSchema() }]
    }
  },

  computed: {
    ...mapGetters("pages", {
      getBreadcrumbs: GET_BREADCRUMBS
    }),

    breadcrumbs(): Record<string, any>[] {
      if (this.block.innerBlocks[4] && this.block.innerBlocks[4].innerHtml) {
        return this.getBreadcrumbs
      }
      return []
    },

    parentAttrs(): Record<string, any> {
      return this.removeParagraphClass(this.getAttributes(this.block.innerBlocks[0]))
    },

    separatorAttrs(): Record<string, any> {
      return this.removeParagraphClass(this.getAttributes(this.block.innerBlocks[1]))
    },

    childAttrs(): Record<string, any> {
      return this.removeParagraphClass(this.getAttributes(this.block.innerBlocks[2]))
    }
  },

  methods: {
    getAttributes,

    getSchema(): JsonObj {
      const entries = this.breadcrumbs
      const list: Record<string, any> = []
      entries.forEach((entry, index) => {
        const path = this.removeDomainFromUrl(entry.url)
        list.push({
          "@type": "ListItem",
          position: index + 1,
          name: entry.text,
          item: this.$config.frontendHost + (path !== this.$config.frontendHost ? path : "")
        })
      })

      if (!entries) {
        return {}
      }

      return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: list
      }
    },

    removeParagraphClass(attrs: Record<string, any>): Record<string, any> {
      attrs.class = attrs.class.replace("av-paragraph ", "")
      return attrs
    },

    removeDomainFromUrl(url: string): string {
      const path = url.match(/^https?:\/\/[A-Za-z0-9:.-]*(.*\/?)$/)
      if (path?.[1] === "/") {
        return this.$config.frontendHost
      }

      if (path) {
        return path[1].replace(/\/$/, "")
      }
      return ""
    }
  }
})
