import { render, staticRenderFns } from "./InternalReviews.vue?vue&type=template&id=e085eab8&lang=pug"
import script from "./InternalReviews.vue?vue&type=script&lang=ts"
export * from "./InternalReviews.vue?vue&type=script&lang=ts"
import style0 from "./InternalReviews.vue?vue&type=style&index=0&id=e085eab8&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Block: require('/app/components/generic/Block.vue').default,Icon: require('/app/components/vantone/Icon.vue').default,Carousel: require('/app/components/generic/Carousel.vue').default,CoreCover: require('/app/components/generic/CoreCover.vue').default})
