
import Vue from "vue"
import { FAQ_REVERSE } from "~/constants/blocks"
import { getBlockAttributes } from "~/utils/block"

export default Vue.extend({
  name: "FaqSection",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    getReverseClass(block) {
      return getBlockAttributes(block).class.includes(FAQ_REVERSE) ? "reverse" : ""
    }
  }
})
