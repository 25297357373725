
import Vue, { VNode } from "vue"
import {
  CORE_IMAGE,
  CORE_HTML,
  CORE_HEADING,
  CORE_TABLE,
  CORE_SEPARATOR,
  YOAST_SEO_BREADCRUMBS
} from "~/constants/blocks"
import { getAttributes, patternCheck, getClassName } from "~/utils/block"
import { replaceUrlS3 } from "~/utils/imageUrlS3"

export default Vue.extend({
  name: "CoreBlock",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },

  computed: {
    getOptions(): Record<string, any> {
      const options = {
        key: this.block.id,
        attrs: { id: this.block.id },
        domProps: { innerHTML: this.block.innerHtml },
        ...this.getAttributes(this.block)
      }

      if (this.block.type === CORE_TABLE && options.hasOwnProperty("style")) {
        // @ts-ignore - Property 'style' does not exist
        delete options.style
      }

      // @ts-ignore - Property 'class' does not exist
      if (options.class?.includes("no-style") && options.hasOwnProperty("style")) {
        // @ts-ignore - Property 'style' does not exist
        delete options.style
      }

      return options
    }
  },

  methods: {
    getAttributes,
    patternCheck,
    getClassName,
    replaceUrlS3,

    createScriptElement(createElement: Function): VNode {
      const regex = /<[^>]*>/gm
      const script = this.block.innerHtml.replace(regex, "").replace(/”/g, '"').replace(/“/g, '"')

      return createElement("script", {
        key: this.block.id,
        attrs: { type: "application/ld+json" },
        domProps: { innerHTML: script }
      })
    },

    createImageElement(createElement: Function, options: Record<string, any>): VNode {
      let img = this.block.innerHtml

      img = this.replaceUrlS3(img, this.$config)

      options.domProps.innerHTML = img

      return createElement(this.block.tagName, options)
    },

    createYoastSeoBreadcrumbsElement(createElement: Function, options: Record<string, any>): VNode {
      options.domProps.innerHTML = this.block.innerHtml.replace(
        "&raquo;",
        "&nbsp;&nbsp;&#47;&nbsp;&nbsp;"
      )

      return createElement("div", options)
    },

    createCoreHtmlElement(createElement: Function, options: Record<string, any>): VNode {
      return createElement("div", {
        key: this.block.id,
        domProps: { innerHTML: this.replaceUrlS3(this.block.innerHtml, this.$config) }
      })
    },

    createHeadingElement(createElement: Function, options: Record<string, any>): VNode {
      if (this.patternCheck(this.block.attributes, "query-")) {
        const queryName = this.getClassName(this.block.attributes) // maintitle
          .split(" ")
          .filter(attr => attr.startsWith("query-"))
          .map(attr => attr.replace("query-", ""))[0]

        const queryValue = this.$route.query[queryName]

        if (process.client) {
          options.class = options.class // remove `query-maintitle` class
            .split(" ")
            .filter(c => c !== "query-maintitle")
            .join(" ")
          if (queryValue) {
            options.domProps.innerHTML = queryValue
            return createElement(this.block.tagName, options)
          }
        }
      }

      return this.createDefaultElement(createElement, options)
    },

    createDefaultElement(createElement: Function, options: Record<string, any>): VNode {
      /* In lieu of a fix on the CMS, enforce the correct tag for a list item */
      if (this.block.type === "CORE_LIST_ITEM" && this.block.tagName === "p") {
        this.block.tagName = "li"
      }
      /* It's unclear why we have to do this. If the parent tag in `innerHtml` is different to `tagName`,
       *  that's probably a bug on the CMS side that should be fixed. Anyhow, here we are, roll with it. */
      options.domProps.innerHTML =
        this.block.type !== CORE_SEPARATOR
          ? this.block.innerHtml.replace(
              new RegExp("<" + this.block.tagName + "[^>]*>+([^]*)<\/" + this.block.tagName + ">"),
              "$1"
            )
          : ""

      return createElement(this.block.tagName, options)
    }
  },

  render(createElement: Function): VNode {
    if (this.block.innerHtml.includes("http://schema.org")) {
      return this.createScriptElement(createElement)
    }

    const blockRenderers = {
      [CORE_IMAGE]: this.createImageElement,
      [YOAST_SEO_BREADCRUMBS]: this.createYoastSeoBreadcrumbsElement,
      [CORE_HTML]: this.createCoreHtmlElement,
      [CORE_HEADING]: this.createHeadingElement
    }
    const renderer = blockRenderers[this.block.type] || this.createDefaultElement

    return renderer(createElement, this.getOptions)
  }
})
