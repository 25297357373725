import { render, staticRenderFns } from "./ImageDialog.vue?vue&type=template&id=25814332&lang=pug"
import script from "./ImageDialog.vue?vue&type=script&lang=ts"
export * from "./ImageDialog.vue?vue&type=script&lang=ts"
import style0 from "./ImageDialog.vue?vue&type=style&index=0&id=25814332&prod&lang=css&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Block: require('/app/components/generic/Block.vue').default})
