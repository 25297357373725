
import { getSizeClasses } from "~/scripts/css-helper"

/** Wrapper around the standard `<button></button>` html tag. */
export default {
  name: "Btn", // eslint-disable-line vue/multi-word-component-names

  props: {
    /** Standard button types */
    type: {
      type: String,
      default: "button"
    },
    /** Colour schema */
    kind: {
      type: String,
      default: "primary",
      validator: value =>
        [
          "primary",
          "red",
          "light",
          "superlight",
          "green",
          "gray",
          "gray-100",
          "gray-140",
          "lightgray",
          "yellow",
          "darkgray",
          "cta-secondary",
          "rebrand-cta",
          "white"
        ].includes(value)
    },
    size: {
      type: String,
      default: "m",
      validator: value => ["xs", "s", "xm", "m", "l", "xl"].includes(value)
    },
    /** Full width element */
    block: {
      type: Boolean,
      default: false
    },
    /** Display just the slot, without icons placeholders */
    btnWithPadding: {
      type: Boolean,
      default: false
    },
    /** Icon shortcut */
    iconLeft: {
      type: Array,
      default: () => []
    },
    /** Icon shortcut */
    iconRight: {
      type: Array,
      default: () => []
    },
    /** Icon size */
    // eslint-disable-next-line vue/require-default-prop
    iconSize: {
      type: Number,
      required: false
    },
    /** Icon colour */
    // eslint-disable-next-line vue/require-default-prop
    iconColor: {
      type: String,
      required: false
    },

    sharpCornersLeft: {
      type: Boolean,
      default: false
    },

    sharpCornersRight: {
      type: Boolean,
      default: false
    },
    /** Nuxt router push call (create a link) */
    to: {
      type: String,
      default: ""
    },
    /** Button does nothing */
    inactive: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    stopClick: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: ""
    },
    wpBlock: {
      type: Object,
      default: () => {},
      required: false
    }
  },

  data() {
    return {
      selfLoading: false
    }
  },

  computed: {
    classes() {
      const classes = []
      classes.push(this.$style[this.kind])
      classes.push(...getSizeClasses(this.size))

      if (this.block) {
        classes.push(this.$style.block)
      }

      // This is intended to be used to get the styles from WP CORE block
      if (this.wpBlock) {
        const block = this.wpBlock
        let bgColour, textColour
        if (block.attributes) {
          block.attributes.forEach(attr => {
            if (attr.name === "backgroundColor") {
              bgColour = attr.value
            }
            if (attr.name === "textColor") {
              textColour = attr.value
            }
          })
        }
        classes.push("bg-" + bgColour)
        classes.push("text-" + textColour)
      }

      /* if (this.icon !== "") {
        classes.push(this.$style.hasIcon)
      }
      classes.push(this.$style[`${this.iconPos}Btn`]) */

      if (this.sharpCornersLeft) {
        classes.push(this.$style.scLeft)
      }
      if (this.sharpCornersRight) {
        classes.push(this.$style.scRight)
      }

      if (this.inactive) {
        classes.push(this.$style.inactive)
      }

      return classes
    },

    iconStyles() {
      const styles = []
      if (this.iconSize) {
        styles.push(`width: ${this.iconSize ? this.iconSize : 25}px`)
      }
      if (this.iconColor) {
        styles.push(`color: ${this.iconColor}`)
      }
      return styles
    },

    iconFontSize() {
      let size = 16
      if (this.iconSize) {
        size = this.iconSize
      } else {
        switch (this.size) {
          case "s":
            size = 16
            break
          case "m":
            size = 18
            break
          case "l":
            size = 21
            break
        }
      }
      return size
    }
  },

  methods: {
    onClick(event) {
      if (event && this.stopClick) event.stopPropagation()
      if (this.inactive) {
        return
      }

      if (this.to === "") {
        this.$emit("click", event)
        if (this.loading === null) {
          this.selfLoading = true
          setTimeout(() => {
            this.selfLoading = false
          }, 5000)
          document.addEventListener("visibilitychange", () => {
            this.selfLoading = false
          })
        }
      } else if (this.target) {
        window.open(this.to, this.target)
      } else {
        this.$router.push(this.to)
      }
    }
  }
}
