
import Vue from "vue"
import { findBlockByClass, getAttributes } from "~/utils/block"

interface ComponentData {
  id: string
  activeSlide: number
  scrollLimit: number
  minimumSlides: number
  nextSlidesKey: number
  slideWidth: number
  slides: Block[]
}

let componentId = 0

export default Vue.extend({
  name: "PageTileNavigation",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    blockId: {
      type: String,
      required: true
    },
    fullImage: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data(): ComponentData {
    return {
      id: "",
      activeSlide: 0,
      scrollLimit: 200,
      minimumSlides: 1,
      nextSlidesKey: 0,
      slideWidth: 468, // basic width with gap
      slides: []
    }
  },

  computed: {
    offSet(): { right: string } {
      return { right: this.slideWidth * this.activeSlide + "px" }
    }
  },

  created(): void {
    if (this.blockId) {
      this.id = this.blockId
    } else {
      componentId += 1
      this.id = `nav-${componentId}`
    }
    if (this.fullImage) {
      this.slideWidth = 392 // full image width with gap
    }
  },

  mounted(): void {
    const slidesWrapper = this.findBlockByClass(this.block, "av-carousel-tiles")

    if (slidesWrapper && slidesWrapper.innerBlocks.length) {
      this.slides = slidesWrapper.innerBlocks
      this.nextSlidesKey = 0
    }
  },

  methods: {
    findBlockByClass,
    getAttributes,

    cloneOriginalSlides(): void {
      let slidesCount = this.slides.length
      const slidesClone: Block[] = [...this.slides]

      while (slidesCount < this.minimumSlides) {
        const nextSlideId = slidesCount - slidesClone.length
        this.slides.push(slidesClone[nextSlideId])
        slidesCount++
      }
      this.nextSlidesKey = this.minimumSlides - slidesClone.length
    },

    scrollLeftMobile(): void {
      if (this.activeSlide > 0) {
        this.activeSlide--
        setTimeout(() => {
          // @ts-ignore
          this.$scrollTo(`#${this.id}-${this.activeSlide}`, 500, {
            container: `#${this.id}`,
            x: true,
            y: false
          })
        }, 500)
      }
    },

    scrollRightMobile(): void {
      if (this.activeSlide < this.slides.length - 1) {
        this.activeSlide++
        setTimeout(() => {
          // @ts-ignore
          this.$scrollTo(`#${this.id}-${this.activeSlide}`, 500, {
            container: `#${this.id}`,
            x: true,
            y: false
          })
        }, 500)
      }
    },

    scrollRight(): void {
      if (this.activeSlide < this.scrollLimit) {
        this.slides.push(this.slides[this.activeSlide + this.nextSlidesKey])
        this.activeSlide++
      }
    }
  }
})
