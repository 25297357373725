
import Vue from "vue"

export default Vue.extend({
  name: "CategoryCard",
  props: {
    title: {
      type: String,
      required: true
    },
    linkPath: {
      type: String,
      required: true
    },
    imagePeeksOut: {
      type: Boolean,
      default: false
    },
    animateFrom: {
      type: String as () => "right" | "bottom",
      default: "right"
    }
  },
  computed: {
    isUk() {
      return this.$i18n.locale === "en"
    }
  }
})
