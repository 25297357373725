
import Vue from "vue"
import { replaceUrlS3 } from "~/utils/imageUrlS3"
import { getMediaById } from "~/services/public/Pages"

interface ImageSize {
  name?: string
  width: number
  height: number
  sourceUrl: string
}

interface ImageData {
  width: number | string
  height: number | string
  url: string
  id?: any
  sourceUrl?: string
  srcSet: string | null
  altText?: string
  mediaDetails: {
    width: number
    height: number
    sizes?: ImageSize[]
  }
  sizes?: ImageSize[]
}

interface ComponentData {
  image: ImageData
}

interface Block {
  innerHtml?: string
  [key: string]: any
}

export default Vue.extend({
  name: "OptimizedImage",

  props: {
    blockId: {
      type: String,
      default: ""
    },
    media: {
      type: String,
      default: ""
    },
    imageData: {
      type: Object as () => ImageData,
      required: true
    },
    lazy: {
      type: Boolean,
      default: false
    },
    idType: {
      type: String,
      default: "DATABASE_ID"
    },
    size: {
      type: String,
      default: null
    },
    block: {
      type: Object as () => Block,
      default: null
    }
  },

  data(): ComponentData {
    return {
      image: {
        width: "auto",
        height: "auto"
      } as ImageData
    }
  },

  computed: {
    extractAltText(): string {
      if (this.block && this.block.innerHtml) {
        const matchAltFromInnerHTMLRegex = new RegExp(/alt="([^"]*?)"/g)
        const matches = this.block.innerHtml.match(matchAltFromInnerHTMLRegex)

        if (matches?.length) {
          return matches[0].slice(5, -1)
        }
      }
      return ""
    },

    imageAttrs(): Record<string, any> {
      const attrs: Record<string, any> = {
        alt: this.image.altText || "image-" + this.imageData.id,
        class: {
          // @ts-ignore
          [this.$style.image]: true,
          "fixed-size": this.imageData.width || this.imageData.height
        },
        style: "--image-width:" + this.image.width + "; --image-height:" + this.image.height + ";"
      }

      if (this.image.width !== "auto") {
        attrs.width = this.image.width
      }
      if (this.image.height !== "auto") {
        attrs.height = this.image.height
      }

      if (!this.lazy && this.blockId === "block-no-0") {
        attrs.fetchpriority = "high"
      }

      return attrs
    }
  },

  methods: {
    replaceUrlS3,

    head(): Record<string, any> | void {
      if (!this.lazy && this.blockId === "block-no-0") {
        if (this.media) {
          return {
            link: [
              {
                rel: "preload",
                as: "image",
                imagesrcset: this.image.sourceUrl
                  ? this.image.sourceUrl
                  : this.replaceUrlS3(this.imageData.url, this.$config),
                media: this.media
              }
            ]
          }
        }

        return {
          link: [
            {
              rel: "preload",
              as: "image",
              href: this.image.sourceUrl
                ? this.image.sourceUrl
                : this.replaceUrlS3(this.imageData.url, this.$config)
            }
          ]
        }
      }
    },

    async fetch(): Promise<void> {
      if (this.imageData.id && process.server) {
        try {
          const response = await getMediaById(this.$config, this.imageData.id, this.idType)

          if (response.data && response.data.mediaItem) {
            const media = response.data.mediaItem
            const selectedSize =
              media.mediaDetails.sizes && this.size && this.size != "full"
                ? media.mediaDetails.sizes.find(size => size.name === this.size)
                : null

            if (selectedSize) {
              this.image.width = selectedSize.width
              this.image.height = selectedSize.height
              this.image.sourceUrl = this.replaceUrlS3(selectedSize.sourceUrl, this.$config)
              this.image.srcSet = null
              this.image.altText = media.altText
            } else {
              this.image = media
              this.image.srcSet = this.replaceUrlS3(this.image.srcSet as string, this.$config)

              if (media.mediaDetails.sizes && media.mediaDetails.sizes.length > 1) {
                media.mediaDetails.sizes.push({
                  width: this.image.mediaDetails?.width,
                  height: this.image.mediaDetails?.height,
                  sourceUrl: this.image.sourceUrl
                })
              } else {
                this.image.width = this.image.mediaDetails?.width as number
                this.image.height = this.image.mediaDetails?.height as number
                this.image.sourceUrl = this.replaceUrlS3(media.sourceUrl, this.$config)
              }
            }
          }
        } catch (err) {
          console.log("Media error", err)
        }
      }

      if (this.imageData.width || this.imageData.height) {
        this.image.width = this.imageData.width
        this.image.height = this.imageData.height
      }
    }
  }
})
