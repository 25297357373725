
import Vue from "vue"

export default Vue.extend({
  name: "MediaText",

  props: {
    block: {
      type: Object as () => Record<string, any>,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    imageData(): { url: string; id: string } {
      const imageUrl = this.block.attributes.find(attribute => attribute.name === "mediaLink")
      const imageId = this.block.attributes.find(attribute => attribute.name === "mediaId")

      return { url: imageUrl.value, id: imageId.value }
    },

    positionClass(): string {
      let positionClass = ""

      this.block.attributes.forEach(attr => {
        if (attr.name !== undefined) {
          if (attr.name == "mediaPosition") {
            positionClass = "position-" + attr.value
          }
        }
      })

      return positionClass
    }
  }
})
