
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import { extractHref, getInnerText, matchProtocolAndHost } from "~/scripts/useful-functions"
import {
  GET_BOOKING_QUOTE,
  GET_BOOKING_QUOTE_LOADING,
  GET_BOOKINGS_COPY
} from "~/store/booking-insights/getters"

export default Vue.extend({
  name: "PanelCTAs",
  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },
  data() {
    return {
      formSubmitted: false
    }
  },
  computed: {
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    }),
    ...mapGetters("booking-insights", {
      headerCopy: GET_BOOKINGS_COPY,
      quoteData: GET_BOOKING_QUOTE,
      quoteDataLoading: GET_BOOKING_QUOTE_LOADING
    }),
    getButtonHref(): string {
      if (this.quoteData?.continueQuoteUrl) return this.quoteData.continueQuoteUrl
      const button = this.block.innerBlocks[0]
      const href = extractHref(button.innerBlocks[0].innerHtml) || ""
      return href.replace(matchProtocolAndHost, this.$config.v4Host)
    },
    getButtonText(): string {
      if (this.quoteDataLoading) return "Loading quote..."
      if (this.quoteData?.continueQuoteUrl) return "Continue quote"
      const button = this.block.innerBlocks[0]
      return getInnerText(button.innerBlocks[0].innerHtml)
    }
  },
  methods: {
    submitForm() {
      this.$emit("submit-form")
    },
    handleCallUsClick() {
      this.$snowplow.trackCallUs({
        phoneNumber: this.getPhoneNumberValue.baseRaw,
        label: "panel_cta_button",
        action: "click",
        extension: this.getPhoneNumberValue.extension
      })
      window.location.href = `tel:${this.getPhoneNumberValue.base}`
    },
    peopleOrPerson(headerCopy: AvBookingsCopy): string {
      if (!headerCopy || !headerCopy.number_of_quotes_in_area) return ""
      return headerCopy.number_of_quotes_in_area === 1 ? "person" : "people"
    }
  }
})
