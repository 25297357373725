
import Vue from "vue"
import { library } from "@fortawesome/fontawesome-svg-core"
import { VueSvgIcon } from "@yzfe/vue-svgicon"
import icons from "~/scripts/icons"

export default Vue.extend({
  name: "Icon",

  components: {
    icon: VueSvgIcon
  },

  props: {
    size: {
      type: Number,
      default: 16
    },
    sizeX: {
      type: Number,
      default: 0
    },
    sizeY: {
      type: Number,
      default: 0
    },
    icon: {
      type: Array as () => string[] | null,
      default: null
    },
    icons: {
      type: Array as () => string[][],
      default: () => []
    },
    stroke: {
      type: String,
      default: ""
    }
  },

  computed: {
    parsedIcons(): string[][] {
      let vbWidth = 0
      let vbHeight = 0
      const iconsArray: Array<string[] | null> = [
        this.icon !== null ? [...this.icon] : null,
        ...this.icons
      ]
      const iconsArrayFiltered = iconsArray.filter(Boolean) as string[][]

      const picons = iconsArrayFiltered.map(icon => {
        // @ts-ignore -- Property 'definitions' does not exist on type 'Library'.
        const faIcon = library.definitions[icon[0]][icon[1]]

        if (faIcon && faIcon.length === 5) {
          if (faIcon[0] > vbWidth) {
            vbWidth = faIcon[0]
          }

          if (faIcon[1] > vbHeight) {
            vbHeight = faIcon[1]
          }

          if (icon?.length === 2) {
            icon.push("")
          }

          if (icon?.length !== 4) {
            icon.push("")
          }

          icon.push(faIcon[4])
        }

        return icon
      })
      return [[String(vbWidth)], [String(vbHeight)], ...picons]
    },

    getSvgIcon(): { name: string; data: { data: string } } | undefined {
      const svg = icons.find(v => v.name === this.icon![1])
      if (svg && svg.name === "ground") {
        svg.data.data = svg.data.data.replace("_fill", "fill")
      }
      if (svg && svg.data.data) {
        svg.data.data = svg.data.data.replace(/_fill="#[0-9a-zA-Z]+"/, "")
        svg.data.data = svg.data.data.replace(/pid="[0-9]+"/, "")
      }

      return svg
    }
  }
})
