
import Vue from "vue"
import "swiper/swiper-bundle.min.css"
import { Swiper } from "swiper/bundle"

interface ComponentData {
  mySwiper: Record<string, any>
  baseParams: Record<string, any>
}

export default Vue.extend({
  name: "Carousel",

  data(): ComponentData {
    return {
      mySwiper: {},
      baseParams: {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        slidesPerView: 1,
        breakpoints: {
          1024: {
            slidesPerView: 2,
            autoplay: false,
            noSwiping: true,
            initialSlide: 0,
            spaceBetween: 30
          }
        },
        spaceBetween: 24
      }
    }
  },

  mounted(): void {
    this.mySwiper = new Swiper(".swiper-container", this.baseParams)
  }
})
